<template>
  <div class="container">
    <aside-profile/>
    <div class="main-box" v-loading="loading">
      <div class="category">
        <div class="header">
          Categories
        </div>
        <div class="category-list-box">
          <div class="category-item" v-for="item in categoryList" :key="item.id">
            <div class="emoji">{{ item.emoji | getEmoji }}</div>
            <div class="title" @click="gotoCategoryArticle(item)">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AsideProfile from '@/components/AsideProfile'
import { listCategory } from '@/api/category'

export default {
  name: 'Category',
  components: {
    AsideProfile
  },
  data () {
    return {
      categoryList: [],
      loading: false
    }
  },
  methods: {
    gotoCategoryArticle (item) {
      this.$router.push({ path: '/categoryArticle', query: { categoryId: item.id } })
    }
  },
  created () {
    this.loading = true
    listCategory().then(res => {
      this.categoryList = res.data
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: space-between;

  .main-box {
    width: 770px;

    .category {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 30px;

      .header {
        font-size: 36px;
        font-weight: bold;
      }

      .category-list-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        .category-item {
          display: flex;
          align-items: center;

          width: 330px;
          height: 40px;
          margin-bottom: 30px;

          .emoji {
            font-size: 32px;
          }

          .title {
            font-size: 24px;
            margin-left: 10px;

            &:hover {
              cursor: pointer;
            }
          }

          &:nth-child(2n-1) {
            margin-right: 30px;
          }
        }
      }
    }

  }
}
</style>
