import request from '@/utils/request'

export function listCategory () {
  return request({
    method: 'get',
    url: 'category'
  })
}

export function getCategory (categoryId) {
  return request({
    method: 'get',
    url: `category/${categoryId}`
  })
}
